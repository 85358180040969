<template>
  <div>
    <left-and-right>
      <div slot="leftDiv">
        <div class="content">
          <div class="card" v-for="item in data.records" :key="item.id">
            <img :src=item.pic>
            <div class="text">
              <h3>{{ item.title }}</h3>
              <p>www.lgb.com | 400-276-99</p>
              <p>{{ item.brief }}
              </p>
              <button @click="toDetails(item.id)">查看供求</button>
            </div>
          </div>
          <pagination v-on:toPage="toPage" :this-page="current" :pages="data.pages"></pagination>
        </div>
      </div>
      <div slot="rightDiv">
        <card ref="card">
          <div slot="heard" class="search">
            <input placeholder="搜索">
            <i><img src="../../assets/image/icon/search.png"></i>
          </div>
        </card>
        <img src="../../assets/image/web.png">
      </div>
    </left-and-right>
  </div>
</template>

<script>
import leftAndRight from "@/components/public/leftAndRight";
import card from "@/components/public/card";
import pagination from "@/components/public/pagination";

export default {
  name: "supply",
  components: {
    leftAndRight,
    card,
    pagination
  },
  data() {
    return {
      data: {},
      current: 1
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$http.get('/safe/getArticleList', {
        params: {
          columnName: '会企供求',
          belong: 1,
          current: this.current,
          size: 5
        }
      }).then((res) => {

        let forms = res.data.records
        for (let i in forms) {
          let pic = forms[i].pic
          if (pic != null) {
            forms[i].pic = this.$constContent.imgUrl + pic
          }
          let time = forms[i].time;
          let date = new Date(time);
          forms[i].time = this.$constContent.dateFormat("YYYY-mm-dd HH:MM", date)
        }
        this.data = res.data
      })


      this.$http.get('/safe/getArticleList', {
        params: {
          columnName: '会企供求',
          belong: 1,
          current: this.current+1,
          size: 3
        }
      }).then((res) => {
        let Cards = []
        let Card = {}
        Card.title = '更多供求'
        Card.id = 0
        Card.content = res.data.records
        Cards.push(Card)
        this.$nextTick(() => {
          this.$refs.card.init(Cards)
        })
      })


    },
    toPage(e) {
      this.current = e
      this.getDataList()
    },

    toDetails(id) {
      this.$router.push('/businessDetails?id=' + id)
      this.$emit('getTitle', '详情')
    }
  }
}
</script>

<style scoped>
.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.card {
  width: 90%;
  margin: 10px 3%;
  padding: 2%;
  border-bottom: 1px solid #dfdfdf;
  display: block;

}

.card img {
  width: 20%;
  float: left;
  cursor: pointer;
}

.card .text {
  width: calc(80% - 10px);
  padding-left: 10px;
  float: left;

}

.card .text p {
  font-size: 14px;
  color: #999999;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

}

.card .text h3, .card .text p {
  margin: 0 0 10px 0;
  cursor: pointer;
}

.card .text button {
  width: 20%;
  height: 32px;
  line-height: 20px;
  padding: 5px 10px;
  border: 1px solid #666666;
  border-radius: 4px;
  background: #ffffff;
  cursor: pointer;
}

.card .text button:hover {
  background: #fc4a1a;
  border: none;
  color: #ffffff;
}

.search {
  width: 100%;
  height: 40px;
  position: relative;
}

.search input {
  height: 38px;
  width: calc(100% - 20px);
  border-radius: 4px;
  padding: 0 10px;
  border: 1px solid #dfdfdf;
}

.search i img {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 12px;
  right: 12px;
}

img {
  width: 100%;
}
</style>